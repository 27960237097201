import * as Sentry from '@sentry/react';
import UAParser from 'ua-parser-js';

const DSN = import.meta.env.VITE_PUBLIC_SENTRY_DSN;
const TRACES_SAMPLE_RATE = import.meta.env.VITE_PUBLIC_SENTRY_TRACES_SAMPLE_RATE || 0;
const REPLAYS_SAMPLE_RATE = import.meta.env.VITE_PUBLIC_SENTRY_REPLAYS_SAMPLE_RATE || 0;
const REPLAYS_ONERROR_SAMPLE_RATE =
  import.meta.env.VITE_PUBLIC_SENTRY_REPLAYS_ONERROR_SAMPLE_RATE || 1;
const NORMALIZE_DEPTH = Number(process.env.VITE_PUBLIC_SENTRY_NORMALIZE_DEPTH) || 3;

const IOS = 'iOS';
const ANDROID = 'Android';
const IOS_MIN_VERSION = 15;
const ANDROID_MIN_VERSION = 9;

const checkIsOldDevices = () => {
  const uaParser = new UAParser();
  const userAgent = uaParser.setUA(navigator.userAgent);

  const deviceOS = userAgent?.getOS();
  const deviceOSName = deviceOS?.name;

  const parsedOSVersion = parseFloat(deviceOS?.version || '');

  return (
    (deviceOSName === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
    (deviceOSName === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION)
  );
};

const filterEventsBeforeSend = <T>(event: T) => {
  if (checkIsOldDevices()) {
    return null;
  }

  return event;
};

const getSentryEnv = () => {
  // ENVIRONMENT provided by gitlab CI
  if (process.env.ENVIRONMENT === 'prod') {
    return 'prod';
  }

  return 'stage';
};

export const initSentry = async () => {
  Sentry.init({
    dsn: DSN,
    integrations: [new Sentry.BrowserTracing()],

    sampleRate: 1, // send 100% of errors to sentry
    // Performance Monitoring
    tracesSampleRate: TRACES_SAMPLE_RATE, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: REPLAYS_SAMPLE_RATE, // This sets the sample rate at 10%.
    replaysOnErrorSampleRate: REPLAYS_ONERROR_SAMPLE_RATE,
    normalizeDepth: NORMALIZE_DEPTH,

    beforeSend: filterEventsBeforeSend,

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    release: process.env.APP_VERSION,
    environment: getSentryEnv(),
    initialScope: {
      tags: {
        'build.date': process.env.APP_BUILD_DATE,
      },
    },

    ignoreErrors: [
      // Common errors (advised to be ignored by Sentry)
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // fb/insta browser related issue https://stackoverflow.com/questions/72488297/referenceerror-getvaluesofautofillinputs-cant-find-variable-paymentautofillco
      'PaymentAutofillConfig',
      '_reportEvent is not defined',
      // Mindea errors
      // paypal integration dependency
      'zoid destroyed all components',
      'Non-Error promise rejection captured',
      'Detected container element removed from DOM',
      "Cannot read properties of undefined (reading 'DomainData').",
      'Non-Error promise rejection captured with value.',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });

  const { Replay } = await import('@sentry/react');
  Sentry.addIntegration(new Replay());
};
