import React, { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

const locale = 'en';

export const LocalizationProvider = ({ children }: PropsWithChildren) => {
  return (
    <IntlProvider locale={locale} defaultLocale={locale}>
      {children}
    </IntlProvider>
  );
};
